import moment from "moment";
// import { isExpired } from "react-jwt";

export const isTokenExpired = (token) => {
  // const decoded = decodeToken(token);
  let expiry = localStorage.getItem("expiry") || moment().add("1", "day");
  // const myExpired = isExpired(token);
  if (moment(expiry) > moment()) {
    return true;
  } else {
    // const now = Date.now().valueOf() / 1000;
    // if (myExpired) {
    //   localStorage.clear();
    //   return true;
    // }
    // if (typeof decoded.exp !== "undefined" && decoded.exp > now) {
    //   return true;
    // }
    return false;
  }
};

export const getDomainName = (url) => {
  
  let domain = url
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    .split("/")[0]
    .split(":")[0]
    .split(".")[0];
  return domain;
};
