import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import React, { forwardRef, useEffect } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
// import {  } from "react-hook-form";
const customComponents = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      {props.data.icon} {children}
    </chakraComponents.Option>
  ),
};
const FormSelect = (props, newRef) => {
  const {
    name,
    label,
    options,
    control,
    defaultValue,
    error,
    rules,
    isMulti,
    onInputChange,
    onChangeNew,
    width,
    disabled,
    unCheck,
    onOpen,
    flex,
    dropIndicator,
    dropIndicatorColor,
    onFocusNew,
    menuHeight,
    myLabel,
    newValue,
    backgroundDisabled,
    height,
    variant,
    displayType,
    placeholder,
    border = "1px solid",
    fontSize = "14px",
    mcolor,
    ...rest
  } = props;
  // const methods = useFormContext();
  const methods = useForm();

  useEffect(() => {
    if (defaultValue) {
     
      methods.setValue(name, defaultValue);
    }
  }, [defaultValue, methods, name]);

  return (
    <FormControl
      isInvalid={
        error ? (error[name]?.message ? error[name] : error?.message) : false
      }
      width={width}
      // height = {height}
    >
      {label && (
        <FormLabel fontSize={14} fontWeight="700" color="#454545">
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue}
        rules={rules}
        render={({
          field: { onChange, onBlur, value, name: fieldName, ref },
          fieldState: { error },
        }) => {
        
          return (
            <Select
              {...rest}
              name={name}
              defaultValue={defaultValue}
              isMulti={isMulti}
              ref={newRef ? newRef : ref}
              onChange={
                onChangeNew
                  ? onChangeNew
                  : (e) => {
                      methods.setValue(name, e);
                      methods.clearErrors(name);
                    }
              }
              variant={variant !== undefined ? variant : "outline"}
              onBlur={onBlur}
              onFocus={onFocusNew}
              value={newValue ? newValue : value}
              options={options}
              placeholder={placeholder}
              selectedOptionStyle={unCheck ? unCheck : "check"}
              onInputChange={onInputChange ? onInputChange : null}
              isDisabled={disabled}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPlacement="auto"
              maxMenuHeight={300}
              inputValue={myLabel}
              fontSize="14px"
              components={customComponents}
              chakraStyles={{
                control: (provided, state) => ({
                  ...provided,
                  width: width,
                  height: height,
                  border: border,
                  fontSize: fontSize,
                  borderColor: "#dddddd",
                  backgroundColor: "brand.700",
                  borderRadius: "8px",
                  _hover: {
                    borderColor: "#dddddd",
                  },
                  _disabled: {
                    background: "#fff",
                    cursor: "not-allowed",
                    opacity: "0.5",
                  },
                  _active: {
                    borderColor: "#dddddd",
                  },
                  _focus: {
                    borderColor: "#dddddd",
                  },
                  fontWeight: "600",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: "transparent",
                  px: 2,
                  cursor: "inherit",
                  display: dropIndicator || "flex",
                  color: dropIndicatorColor,
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                menuList: (provided) => ({
                  ...provided,
                  minWidth: "auto",
                  maxHeight: menuHeight || 300,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 999,
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  color: "#454545",
                }),
                inputContainer: (provided) => ({
                  width: "100%",
                  display: displayType ? displayType : "contents",
                }),
                multiValue: (provided,state) => ({
                  ...provided,
                  // background: mcolor || "brand.900",
                  background: state.data.id === true ? "red" : "brand.900",
                  color: "brand.400",
                }),
              }}
              onMenuOpen={onOpen}
            />
          );
        }}
      />
      {error && (
        <FormErrorMessage>
          {error ? (error[name] ? error[name]?.message : error?.message) : ""}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default forwardRef(FormSelect);
